.table-verbforms {

	th {
		position: relative;
		white-space: nowrap;
		width: 33%;
		min-width: 3rem;
		font-weight: normal;

		& > span {
			position: absolute;
			left: 4px;
			right: 0;
			overflow: hidden;
			text-overflow: ellipsis;

			&:hover {
				right: auto;
				background-color: var(--#{$prefix}body-bg);
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.table-verbforms-lg-wide th {
		width: 20%;
	}
}

.verb-unknown {
	opacity: 0.6;
}

.pronoun {
	color: var(--#{$prefix}secondary-color);
	font-weight: normal;
}

.conjugation-compound {
	white-space: nowrap;
}

.conjugation-empty {
	color: var(--#{$prefix}secondary-color);
	font-style: italic;
}

.conjugation-1 {
	color: $green-600;
}

.conjugation-2 {
	color: $orange-600;
}

.conjugation-3 {
	color: $red-600;
}

@include color-mode(dark) {
	.conjugation-1 {
		color: $green-400;
	}

	.conjugation-2 {
		color: $orange-400;
	}

	.conjugation-3 {
		color: $red-400;
	}
}