// terrassa template

$min-contrast-ratio: 3;

$brand-primary: #ef4135; // red
$brand-secondary: #0055a4; // blue

$primary: $brand-primary;
$secondary: $brand-secondary;

$headings-color: darken($secondary, 10%);

$navbar-light-color: #fff;
$navbar-light-hover-color:          #fff;
$navbar-light-active-color:         #fff;
$navbar-light-disabled-color:       #ddd;
$navbar-light-toggler-border:       #fff;

$navbar-default-bg: $secondary;

@import "base";
@import "clock";

#topnav {
	background-color: $secondary;
}

#jumbotron {
	@include gradient-radial($body-bg, lighten($brand-secondary, 33%));

	h1, h2, h3, h4 {
		color: $primary;
	}

	&[data-background] {
		color: $white;
		background: $gray-800 none;

		p, h1, h2, h3, h4 {
			text-shadow: 1px 1px 4px $black;
		}

		a:not([class]) {
			color: $secondary;
		}

		h1, h2, h3, h4 {
			color: $white;
		}

		.input-group {
			border-radius: $border-radius-lg;
			box-shadow: 1px 1px 4px $black;
		}
	}
}

footer {
	background-color: lighten($brand-secondary, 33%);
	color: $gray-400;

	a, .nav-link {
		color: $gray-200;

		&:hover,
		&:focus {
			color: $white;
		}
	}
}

@include color-mode(dark) {
	#jumbotron {
		@include gradient-radial($body-bg-dark, $body-secondary-bg-dark);
	}

	footer {
		background-color: $body-secondary-bg-dark;
		color: $gray-500;
	}
}
