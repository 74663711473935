@keyframes turn {
	100% {
		transform: rotate(360deg);
	}
}

.clock {
	border: .2em solid $input-border-color;
	border-radius: 50%;
	background: $gray-100 url('../images/station_clock.svg') no-repeat center;
	background-size: 96%;
	position: relative;
    @include box-shadow(inset 0 0 0.5em rgba(0,0,0,.125));
	margin-top: 0;
	margin-bottom: 1rem;

	&::before {
		content: "";
		display: block;
		padding-top: 100%;		
	}

	&.show-hands .hours-container,
	&.show-hands .minutes-container,
	&.show-hands .seconds-container {
		display: block;
	}

	&.bounce .hours-container,
	&.bounce .minutes-container {
	    transition: transform 0.3s cubic-bezier(.4,2.08,.55,.44);
	}

	&.run .seconds-container {
		animation: turn 59s 0s 1 linear;
	}

	&.has-error {
		border: .2em solid $danger;
		background-color: $danger-bg-subtle;
	}
	&.has-success {
		border: .2em solid $success;
		background-color: $success-bg-subtle;
	}
	&.has-border {
		border: .2em solid $primary;
	}
}

.hours-container,
.minutes-container,
.seconds-container {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: none;
	pointer-events: none;
}

.hours {
	background: #000;
	height: 40%;
	left: 48.25%;
	position: absolute;
	top: 22%;
	transform-origin: 50% 71%;
	width: 3.5%;
}

.minutes {
	background: #000;
	height: 55%;
	left: 48.25%;
	position: absolute;
	top: 7%;
	transform-origin: 50% 78.5%;
	width: 3.5%;
}

.seconds-container::after {
	background: #e00;
	border-radius: 50%;
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 4%;
	height: 4%;
	z-index: 10;
}

.seconds {
    background: #e00;
    height: 42%;
    position: absolute;
    left: 49.25%;
    top: 20%;
    transform-origin: 50% 71%;
    width: 1.5%;
    z-index: 8;

	&::before {
		background: #e00;
		border-radius: 50%;
		content: "";
		position: absolute;
		top: -9%;
		left: -200%;
		height: 18%;
		width: 500%
	}
}
