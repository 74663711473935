.autocomplete-result-list {
	max-height: $dropdown-padding-y * 2 + ($line-height-base + $dropdown-item-padding-y * 2) * 5;
	margin-top: $input-border-width;
	overflow-y: auto;
}

.autocomplete-result {
	&:hover,&[aria-selected=true] {
		color: $dropdown-link-hover-color;
		text-decoration: if($link-hover-decoration == underline, none, null);
		@include gradient-bg($dropdown-link-hover-bg);
	}
}
