@import "bootstrap";

@import "icomoon";

@import "com_verb";
@import "mod_topverbs";
@import "autocomplete-bs";

@mixin transform($transforms) {
    transform: $transforms;
}

#topnav {
	@include media-breakpoint-down(md) {
		.navbar-nav-scroll {
			max-width: 100%;
			height: 2.5rem;
			overflow: hidden;

			.navbar-nav {
				padding-bottom: 2rem;
				overflow-x: auto;
				white-space: nowrap;
				-webkit-overflow-scrolling: touch;
			}
		}
	}

	@include media-breakpoint-up(md) {
		@supports (position: sticky) {
			position: sticky;
			top: 0;
			z-index: 1020;
			transition: opacity 1s;

			html:not([data-scroll='0']) & {
				opacity: 0.9;
			}
		}
	}
}

@include media-breakpoint-up(md) {
	@supports (position: sticky) {
		body, html {
			scroll-padding-top: 4rem;
		}
	}
}

#above, #left, #content, #right, #below {
	margin: ($spacer * 2) 0;
}

footer {
	padding: $spacer 0;

	.nav-link {
		padding: $nav-link-padding-y $nav-link-padding-x $nav-link-padding-y 0;
	}

	.float-end .nav-link {
		padding: $nav-link-padding-y 0 $nav-link-padding-y $nav-link-padding-x;
	}
}

#jumbotron {
	position: relative;

	.container {
		position: relative;
	}

	.shadow-container {
		box-shadow: inset 0 1rem 1rem -1rem $gray-800, inset 0 -1rem 1rem -1rem $gray-800;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.background-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: clip;

		.background-container {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center;
			transform: scale(1.05);
			opacity: 0;
			transition: opacity 1s, filter 5s;

			&.enabled {
				opacity: 1;
				filter: blur(2px);
			}
		}
	}
}

#left-sticky, #right-sticky {
	position: sticky;
	top: 4rem;
}

.columns {
	column-count: 2;
}

@include media-breakpoint-up(sm) {
	.columns {
		column-count: 3;
	}
}

@include media-breakpoint-up(lg) {
	.columns {
		column-count: 4;
	}
}

@include media-breakpoint-up(xl) {
	.columns {
		column-count: 5;
	}
}

@include media-breakpoint-up(xxl) {
	.columns {
		column-count: 6;
	}
}

.grow {
	transition: all .2s ease-in-out; 

	&:hover {
		@include transform( scale(1.1) );
	}
}

// extra common definitions

// missing definition in BS
h1, h2, h3, h4 {
	text-wrap: balance;

	&:not([class]) {
		margin-top: $spacer;
	}
}

dd {
	margin-left: 2rem;
}

// animations

@keyframes shake {
	25% {
		transform: translateX(4px)
	}
	50% {
		transform: translateX(-4px)
	}
	75% {
		transform: translateX(4px)
	}
}

@keyframes parallax {
	0% {
		top: -20%;
	}
	100% {
		top: 20%;
	}
}

@media (prefers-reduced-motion: no-preference) {
	input.is-invalid {
		animation: shake 300ms;
	}

	@supports (animation-timeline: view()) {
		#jumbotron.parallax .background-container {
			transform: scale(1.2); 
			animation: parallax linear;
			animation-timeline: view(y);
			animation-range: entry exit;
		}
	}
}
