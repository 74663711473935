// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.

$theme-colors: map-remove($theme-colors, "light", "dark");

$font-family-base: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, system-ui, sans-serif;

$enable-responsive-font-sizes: true;
$enable-shadows: true;
// $enable-gradients: true;
// $enable-validation-icons: false;

// $color-mode-type: media-query;

$custom-control-indicator-checked-bg: map-get($theme-colors, success);
$custom-control-indicator-checked-border-color: map-get($theme-colors, success);

$dropdown-min-width: 8rem;

$link-decoration: none;
$link-hover-decoration: underline;

$headings-color-dark: $white;

$utilities: map-merge(
	$utilities,
	(
		"order": (
			responsive: true,
			property: order,
			values: (
			first: -1,
			0: 0,
			1: 1,
			2: 2,
			3: 3,
			4: 4,
			5: 5,
			6: 6,
			7: 7,
			last: 8,
		)
	)
));