@mixin icomoon ($name, $path, $icons) {
	@font-face {
		font-family: '#{$name}';
		src:
			url('#{$path}.woff2?ubyroh') format('woff2'),
			url('#{$path}.woff?ubyroh') format('woff'),
			url('#{$path}.ttf?ubyroh') format('truetype'),
			url('#{$path}.svg?ubyroh##{$name}') format('svg');

		font-weight: normal;
		font-style: normal;
		font-display: block;
	}

	[class^="icon-"], [class*=" icon-"] {
		// use !important to prevent issues with browser extensions that change fonts
		font-family: '#{$name}' !important;
		display: inline-block;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;

		// Better Font Rendering
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	@each $icon, $content in $icons {
		.icon-#{$icon} {
			// add content
			&:before {
				content: $content;
			}
		}
	}
}

// define the name of your font, the path to your font, and the icons 
@include icomoon('icomoon', '../fonts/icomoon', (
	moon: "\e900",
	pencil: "\e905",
	copy: "\e92c",
	qrcode: "\e938",
	envelop: "\e945",
	printer: "\e954",
	search: "\e986",
	cog: "\e994",
	shield: "\e9b4",
	earth: "\e9ca",
	link: "\e9cb",
	sun: "\e9d4",
	contrast: "\e9d5",
	heart: "\e9da",
	warning: "\ea07",
	info: "\ea0c",
	cross: "\ea0f",
	checkmark: "\ea10",
	backward: "\ea1f",
	forward: "\ea20",
	previous: "\ea23",
	next: "\ea24",
	volume-high: "\ea26",
	arrow-right: "\ea3c",
	arrow-left: "\ea40",
	share: "\ea82",
	google: "\ea88",
	facebook: "\ea90",
	instagram: "\ea92",
	twitter: "\ea96",
	youtube: "\ea9d",
	joomla: "\eab5",
	linkedin: "\eaca",
	x: "\e901"
));
